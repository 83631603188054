import { Data } from 'interfaces/customers';

export function createData(
  id: { value: number | string },
  status: { value: JSX.Element; sort?: string },
  publicName: { value: JSX.Element; sort?: string },
  address: { value: JSX.Element; sort?: string },
  phone: { value: JSX.Element; sort?: string },
  email: { value: JSX.Element; sort?: string },
  branches: { value: JSX.Element; sort?: string },
  amountPerWeight: { value: JSX.Element; sort?: string },
  createdAt: { value: JSX.Element; sort?: string },
  invoicePeriod: { value: JSX.Element; sort?: string },
  paymentPeriod: { value: JSX.Element; sort?: string },
  legalId: { value: JSX.Element; sort?: string },
  activityCode: { value: JSX.Element; sort?: string },
  legalName: { value: JSX.Element; sort?: string },
  code: { value: JSX.Element; sort?: string },
  updatedAt: { value: JSX.Element; sort?: string },
  vatNumber: { value: JSX.Element; sort?: string },
  score: { value: JSX.Element; sort?: string },
  orderFrequency: { value: JSX.Element },
  _salesman: { value: JSX.Element },
  _customerType: { value: JSX.Element },
  action: { value: JSX.Element },
  invitation: { value: JSX.Element },
  lastOrderDate: { sort: string; value: React.JSX.Element },
): Data {
  return {
    id,
    publicName,
    address,
    email,
    phone,
    branches,
    amountPerWeight,
    createdAt,
    invoicePeriod,
    paymentPeriod,
    code,
    legalName,
    legalId,
    activityCode,
    updatedAt,
    vatNumber,
    _salesman,
    _customerType,
    action,
    status,
    score,
    orderFrequency,
    invitation,
    lastOrderDate,
  };
}
