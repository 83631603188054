import { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { VonageClient, ConfigRegion, LoggingLevel } from '@vonage/client-sdk';

import {
  useCreateVoiceCallMutation,
  useCreateVoiceJwtMutation,
} from 'common/services/calls';

const isDevMode = process.env.REACT_APP_CALL_MODE !== 'production';

export function formatPhoneNumber(phone: string) {
  if (isEmpty(phone)) {
    return null;
  }

  if (phone.length === 10) {
    return `33${phone.slice(1)}`;
  } else if (phone.includes('+')) {
    return phone.replace('+', '');
  }

  return phone;
}

export function usePhoneCallHook(callId, phone) {
  const [client] = useState(() => {
    // Initialize client with optional config (default: ERROR logging, US region).
    const client =
      window.vonageClient ||
      new VonageClient({
        loggingLevel: LoggingLevel.Error,
        region: ConfigRegion.EU,
      });

    return client;
  });

  const callPhone = isDevMode
    ? formatPhoneNumber('0643676993')
    : formatPhoneNumber(phone);

  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<string | null>(null);
  const [voiceCallId, setVoiceCallId] = useState<string | null>(null);
  const [sessionUser, setSessionUser] = useState<any>();
  const [error, setError] = useState<any>();
  const [createVoiceCall, { isLoading }] = useCreateVoiceCallMutation();
  const [createVoiceJwt] = useCreateVoiceJwtMutation();

  useEffect(() => {
    const members: string[] = [];

    const eventHandler = (event: any) => {
      if (event.kind == 'member:invited') {
        console.log(`Member invited: ${event.body.memberId}`);
      } else if (event.kind == 'member:joined') {
        if (members?.length === 0) {
          setStatus('ringing');
        }

        members.push(event.body.memberId);
        console.log(`Member joined: ${event.body.memberId}`);
      } else if (event.kind == 'member:left') {
        console.log(`Member left: ${event.body.memberId}`);
        members.splice(members.indexOf(event.body.memberId), 1);

        if (members?.length === 0) {
          setStatus(null);
        }
      } else if (event.kind == 'custom') {
        console.log(`Custom event: ${event.body}`);
      } else {
        console.log(event);
      }

      if (members.length > 1) {
        setStatus('connected');

        // Update call status to connected
      }
    };

    const listener = client.on('conversationEvent', eventHandler);

    return () => {
      client.off('conversationEvent', listener);
    };
  }, []);

  function handleHangup() {
    if (voiceCallId) {
      client.hangup(voiceCallId);
      setStatus(null);
    }
  }

  const handleCall = async () => {
    if (isEmpty(phone)) {
      console.error('Phone number is empty');
      return;
    }

    console.log('Initiating call to: ', formatPhoneNumber(phone));

    setLoading(true);

    try {
      if (!window.vonageJwt) {
        const { jwt } = await createVoiceJwt({}).unwrap();
        window.vonageJwt = jwt;

        const session = await client.createSession(jwt);
        window.vonageSession = session;
      }

      const meUser = await client.getUser('me');

      setSessionUser(meUser);

      if (window.vonageSession && meUser) {
        client
          .serverCall({ to: callPhone, user: meUser, bampteeCallId: callId })
          .then(_callId => {
            setVoiceCallId(_callId);
            setLoading(false);

            // Create voice call in db here and link to the callId
            if (_callId) {
              createVoiceCall({
                id: _callId,
                callId,
                phone: callPhone,
              }).unwrap();
            }
          })
          .catch(error => {
            console.error(`Error making call: ${error}`);
            setError(error);
            setLoading(false);
          });
      }
    } catch (e) {
      console.log(e);
      setError(e);
      setLoading(false);
    }
  };

  return {
    status,
    sessionUser,
    voiceCallId,
    handleCall,
    handleHangup,
    error,
    client,
    loading,
  };
}

export function useVoiceCallHook(userId) {
  const [client] = useState(() => {
    // Initialize client with optional config (default: ERROR logging, US region).
    const client =
      window.vonageClient ||
      new VonageClient({
        loggingLevel: LoggingLevel.Error,
        region: ConfigRegion.EU,
      });

    return client;
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<string | null>(null);
  const [voiceCallId, setVoiceCallId] = useState<string | null>(null);
  const [sessionUser, setSessionUser] = useState<any>();
  const [error, setError] = useState<any>();
  const [createVoiceCall, { isLoading }] = useCreateVoiceCallMutation();
  const [createVoiceJwt] = useCreateVoiceJwtMutation();

  useEffect(() => {
    const members: string[] = [];

    const eventHandler = (event: any) => {
      console.log(event)
      if (event.kind == 'member:invited') {
        console.log(`Member invited: ${event.body.memberId}`);
      } else if (event.kind == 'member:joined') {
        if (members?.length === 0) {
          setStatus('ringing');
        }

        members.push(event.body.memberId);
        console.log(`Member joined: ${event.body.memberId}`);
      } else if (event.kind == 'member:left') {
        console.log(`Member left: ${event.body.memberId}`);
        members.splice(members.indexOf(event.body.memberId), 1);

        if (members?.length === 0) {
          setStatus(null);
        }
      } else if (event.kind == 'custom') {
        console.log(`Custom event: ${event.body}`);
      } else {
        console.log(event);
      }

      if (members.length > 1) {
        setStatus('connected');

        // Update call status to connected
      }
    };

    const listener = client.on('conversationEvent', eventHandler);

    return () => {
      client.off('conversationEvent', listener);
    };
  }, []);

  function handleHangup() {
    if (voiceCallId) {
      client.hangup(voiceCallId);
      setStatus(null);
    }
  }

  const handleCall = async () => {
    if (isEmpty(userId)) {
      console.error('User id is empty');
      return;
    }

    console.log('Initiating call to: ', userId);

    setLoading(true);

    try {
      if (!window.vonageJwt) {
        const { jwt } = await createVoiceJwt({}).unwrap();
        window.vonageJwt = jwt;

        const session = await client.createSession(jwt);
        window.vonageSession = session;
      }

      const meUser = await client.getUser('me');

      setSessionUser(meUser);

      if (window.vonageSession && meUser) {
        client
          .serverCall({ to: userId, user: meUser })
          .then(_callId => {
            setVoiceCallId(_callId);
            setLoading(false);

            // Create voice call in db here and link to the callId
            if (_callId) {
              createVoiceCall({ id: _callId, userId }).unwrap();
            }
          })
          .catch(error => {
            console.error(`Error making call: ${error}`);
            setError(error);
            setLoading(false);
          });
      }
    } catch (e) {
      console.log(e);
      setError(e);
      setLoading(false);
    }
  };

  return {
    status,
    sessionUser,
    voiceCallId,
    handleCall,
    handleHangup,
    error,
    client,
    loading,
  };
}
