import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { ChatContext } from 'app/components/Chat';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { TopBar } from './components/TopBar';
import { EmptyState } from './components/EmptyState';
import { AddContacts } from './components/AddContacts';
import { ChatCard } from './components/ChatCard';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'app/slices/auth/selectors';
import { getId } from 'common/helpers/document';
import { StateSetter } from 'types';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import { ContactCardSkeleton } from './components/ContactCardSkeleton';

dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(isYesterday);

interface Props {
  filteredChatsList: any;
  setFilteredChatsList: StateSetter<any>;
  handleGetMoreChats: () => void;
  isLoadingChats: boolean;
  hasMore: boolean;
  chatsOffset: number;
}

export const ContactsList: React.FC<Props> = ({
  filteredChatsList,
  setFilteredChatsList,
  handleGetMoreChats,
  isLoadingChats,
  hasMore,
  chatsOffset,
}) => {
  const {
    contacts,
    search,
    setSearch,
    contactsOffset,
    lastListMessageCopy,
    currentUserId,
    selectedUser,
    totalChats,
    initialItemsCount,
  } = useContext(ChatContext);
  const [addNewChatMode, setAddNewChatMode] = useState(false);
  const [newChat, setNewChat] = useState<any>(null);
  const [allParticipantsIds, setAllParticipantsIds] = useState<string[]>([]);
  const { i18n, t } = useTranslation();
  const locale = i18n.language;
  const authUser = useSelector(selectAuthUser);
  const listRef: any = useRef(null);

  const formatTimestamp = timestamp => {
    const date = dayjs(timestamp);

    if (date.isToday()) {
      return date.format('HH:mm');
    } else if (date.isYesterday()) {
      return t('yesterday');
    } else {
      return date.format('D MMMM, YYYY');
    }
  };

  useEffect(() => {
    if (lastListMessageCopy) {
      setFilteredChatsList(prevMessage => {
        const existingMessage = prevMessage?.some(
          msg => msg?._id === lastListMessageCopy?._id,
        );
        if (existingMessage) {
          return prevMessage.map(msg => {
            if (msg?._id === lastListMessageCopy?._id) {
              return lastListMessageCopy;
            }
            return msg;
          });
        } else {
          return [lastListMessageCopy, ...prevMessage];
        }
      });
    }
  }, [lastListMessageCopy]);

  useEffect(() => {
    if (selectedUser) setAddNewChatMode(false);
  }, [selectedUser]);

  useEffect(() => {
    if (!listRef.current) return;
    listRef?.current?.addEventListener('scroll', () =>
      handleScroll(isLoadingChats, hasMore),
    );
    return () => {
      listRef?.current?.removeEventListener('scroll', () =>
        handleScroll(isLoadingChats, hasMore),
      );
    };
  }, [listRef.current, isLoadingChats, hasMore]);

  useEffect(() => {
    if (filteredChatsList?.length === 0) return;
    const participants: any = [];
    filteredChatsList.forEach(chat => {
      const participant = chat?.participants?.filter(
        user => user?.user !== currentUserId,
      );
      participants.push(participant[0]?.user || currentUserId);
    });
    setAllParticipantsIds(participants);
  }, [filteredChatsList]);

  const handleScroll = (isLoadingChats, hasMore) => {
    const { scrollTop, clientHeight, scrollHeight } = listRef.current;
    if (
      clientHeight + scrollTop === scrollHeight &&
      !isLoadingChats &&
      hasMore
    ) {
      handleGetMoreChats();
    }
  };

  return (
    <Wrapper translate="no">
      <TopBar
        search={search}
        setSearch={setSearch}
        addNewChatMode={addNewChatMode}
        setAddNewChatMode={setAddNewChatMode}
        chatsCount={filteredChatsList?.length || 0}
      />
      {addNewChatMode &&
      contacts &&
      (contacts?.length > 0 || contactsOffset > 0) ? (
        <AddContacts setNewChat={setNewChat} />
      ) : (
        <ChatsListWrapper ref={listRef}>
          {filteredChatsList?.length > 0 ? (
            <>
              {selectedUser &&
                !allParticipantsIds?.includes(selectedUser?._id) && (
                  <ChatCard
                    pinned={false}
                    id={''}
                    fullUser={selectedUser}
                    selectedUserId={selectedUser?._id}
                    pictureUrl={selectedUser?.pictureUrl}
                    fullName={`${selectedUser?.firstName} ${selectedUser?.lastName}`}
                    role={`${selectedUser?.role?.localeName[`${locale}`]}`}
                    // onlineStatus={chat?.onlineStatus}
                    lastMessageAt={''}
                    lastMessage={''}
                  />
                )}
              {filteredChatsList?.map(chat => {
                return chat?.participantsDetails
                  ?.filter(
                    participant =>
                      chat?.participantsDetails?.length === 1 ||
                      participant?._id !== currentUserId,
                  )
                  .map(listItem => {
                    const user: any = listItem?.agents?.filter(
                      agent =>
                        agent?._organization ===
                        authUser?._currentOrganization?._id,
                    )[0];
                    return (
                      <ChatCard
                        pinned={chat?.pinned || false}
                        id={chat?._id}
                        fullUser={user}
                        selectedUserId={listItem?._id}
                        pictureUrl={user?.pictureUrl}
                        fullName={
                          !!user?.firstName || !!user?.lastName
                            ? `${user?.firstName} ${user?.lastName}`
                            : null
                        }
                        role={
                          user?._role?.localeName[`${locale}`]
                            ? `${user?._role?.localeName[`${locale}`]}`
                            : null
                        }
                        // onlineStatus={chat?.onlineStatus}
                        lastMessageAt={formatTimestamp(
                          chat?.lastMessage?.createdAt,
                        )}
                        lastMessage={`${
                          chat?.lastMessage?.sender === currentUserId
                            ? `${t('you')}: `
                            : ''
                        } ${
                          chat?.lastMessage?.content
                            ? chat?.lastMessage?.contentType === 'text'
                              ? chat?.lastMessage?.content
                              : chat?.lastMessage?.contentType === 'images'
                                ? '🖼️ Image'
                                : `📄 ${chat?.lastMessage?.name}`
                            : ''
                        }`}
                        unreadMessagesCount={chat?.messages[0]?.unreadCount}
                      />
                    );
                  });
              })}
              {isLoadingChats && (
                <ContactCardSkeleton
                  length={Math.min(totalChats - chatsOffset, initialItemsCount)}
                />
              )}
            </>
          ) : (
            <EmptyState setAddNewChatMode={setAddNewChatMode} />
          )}
        </ChatsListWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 488px;
  flex: 1;
  border-right: 1px solid ${themes?.default?.gainsboro2};
  position: relative;

  @media screen and (max-width: 1200px) {
    max-width: none;
  }
`;

const ChatsListWrapper = styled.div`
  height: calc(100% - 24px);
  margin-bottom: 20px;
  padding: 1px 16px 1px 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
