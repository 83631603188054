import * as React from 'react';
import { useState, useEffect } from 'react';
import { toString } from 'lodash';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import { Controller, useForm } from 'react-hook-form';

import styled from 'styled-components/macro';
import { MenuItem, TextField, Select } from '@mui/material';
import { isEmpty } from 'lodash';
import {
  useContactMutation,
  usePostConversationCustomerMutation,
} from 'common/services/customerApi';
import FileUpload from 'react-material-file-upload';
import { useUploadCustomerMutation } from 'common/services/customerApi';

export function ContactForm({ addresses, token, password, onOk }) {
  const { t } = useTranslation();
  const [createConversation] = usePostConversationCustomerMutation();
  const [uploadFile] = useUploadCustomerMutation();
  const [loading, setLoading] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      addressId: toString(addresses[0]._id),
      subject: '',
      message: '',
    },
  });

  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
  } = methods;

  const addressId = watch('addressId');
  const subject = watch('subject');
  const message = watch('message');
  const onSubmit = async data => {
    setLoading(true);

    try {
      const filesUrl = [];

      if (files.length > 0) {
        for (const file of files) {
          const formData = new FormData();
          formData.append('file', file);

          const { url } = await uploadFile({
            formData,
            token,
            password,
            preserveName: true,
            type: 'attachment',
          }).unwrap();

          // @ts-ignore
          filesUrl.push(url);
        }
      }

      createConversation({
        token: token,
        subject: subject,
        content: message,
        contactAddressId: addressId,
        files: filesUrl,
        password,
      }).unwrap();

      setLoading(false);
      onOk();
    } catch (e) {
      console.log('e', e);
      setLoading(false);
      setDisplayError(true);
    }
  };

  return (
    <FormWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="addressId"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select {...field} fullWidth>
              {addresses.map((address, index) => (
                <MenuItem
                  value={toString(address._id)}
                >{`${address.label}`}</MenuItem>
              ))}
            </Select>
          )}
        />

        <Controller
          name="subject"
          control={control}
          rules={{ required: true, minLength: 2 }}
          render={({ field }) => (
            <TextField
              {...field}
              label={t('customerPublic.contactForm.subject')}
              margin="normal"
              variant="outlined"
              fullWidth
            />
          )}
        />

        <Controller
          name="message"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              multiline
              rows={6}
              fullWidth
              margin="normal"
              label={t('customerPublic.contactForm.message')}
              variant="outlined"
              style={{ margin: '0 0 8px 0' }}
            />
          )}
        />

        <FileUpload
          buttonText={t('AttachmentUpload.buttonTitle')}
          title={t('AttachmentUpload.title')}
          value={files}
          onChange={setFiles}
        />

        <FormActions>
          <Button
            disableElevation
            size="large"
            type="submit"
            onClick={() => onOk()}
          >
            {t('common.buttons.cancel')}
          </Button>

          {loading ? (
            <LoadingButton
              variant="contained"
              disableElevation
              loading
              loadingPosition="start"
              size="large"
            >
              {t('common.buttons.submit')}
            </LoadingButton>
          ) : (
            <Button
              disabled={!subject || !message || !isEmpty(errors)}
              variant="contained"
              disableElevation
              size="large"
              type="submit"
              style={{ marginLeft: 16 }}
            >
              {t('common.buttons.submit')}
            </Button>
          )}
        </FormActions>
      </form>

      <Snackbar
        open={displayError}
        autoHideDuration={6000}
        onClose={() => setDisplayError(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          variant="filled"
          onClose={() => setDisplayError(false)}
          severity="error"
          sx={{ width: '100%' }}
        >
          {t('auth.page.login.error')}
        </Alert>
      </Snackbar>
    </FormWrapper>
  );
}

const FormWrapper = styled.div`
  width: 100%;
  padding: 0 32px;

  @media (max-width: 1000px) {
    padding: 0;
  }
`;

const FormActions = styled.div`
  width: 100%;
  padding: 16px 0;
  display: flex;
  justify-content: flex-end;
`;
