import React, { useMemo, useState } from 'react';
import { createData } from 'utils/pages/customers';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { Button, Tooltip } from '@mui/material';
import { ActionPopover } from 'app/components/Popover';
import { PopoverEl } from 'app/components/Popover';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { themes } from 'styles/theme/themes';
import { StatusCustomer } from '../../app/components/StatusCustomer';
import { GetCustomerFrequency } from 'app/pages/Customers/components/CustomerFrequencyIcons';
import {
  MarkunreadMailboxOutlined,
  PersonAddAlt1Outlined,
} from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import CancelIcon from '@mui/icons-material/Cancel';
import { selectAuthUser } from 'common/store/auth/selectors';
import { checkUserAcl } from '../../common/helpers/acl';
import { CreditScoreBadge } from '../../app/components/CreditScoreBadge';
import EllipsisText from 'app/components/EllipsisText';
import If from 'app/components/If';
import { useRefreshCreditScoreMutation } from 'common/services/customerApi';
import { useToaster } from '../useToaster';
import { selectActivityCodes } from 'common/store/organization/selectors';
import { AddressIcon } from 'app/components/AddressIcon';
import { getAddressLabel } from 'app/helpers/helpers';
import { IconButton } from '../../app/components/Dialog/styles';
import { FileMagnifyingGlass, Warning } from '@phosphor-icons/react';
import InvitationSent from 'app/components/Customers/InvitationSent';
import { useAbility } from 'hooks/Abilities';
import { number, string } from 'prop-types';
import { SalesmanPopup } from 'app/pages/Customers/components/SalesmanPopup';
import FormatNumber from 'app/components/FormatNumber';
import { useApplication } from 'hooks/useApplication';
import { StatusLastOrder } from '../../app/components/StatusLastOrder';

interface CustomersData {
  creditScore: any;
  _id: string;
  id: number | string;
  code: string;
  createdAt: string;
  email: string;
  invoicePeriod: string;
  paymentPeriod: number;
  legalId: string;
  legalStatus: string;
  legalStatusUpdatedAt: string;
  legalName: string;
  activityCode: string;
  phone: string;
  publicName: string;
  updatedAt: string;
  vatNumber: string;
  token: string;
  tokenPassword: string;
  score: string;
  _salesman: {
    companyEmail: string;
    firstName: string;
    fullName: string;
    id: number | string;
    shortName: string;
    lastName: string;
  };
  _customerType: {
    label: string;
  };
  status: string;
  invitedAt: any;
  _lastLoggedUser: string;
  handleEditCustomer: () => void;
  cache?: {
    decks?: { _id: string; code: string; location: any }[];
    stats?: {
      amountPerWeight?: number;
      lastOrderDate?: Date;
    };
    financials?: {
      averageByMonth?: number;
      averageByWeek?: number;
      countActiveOrders?: number;
      frequencyStatus?: string;
    };
  };
  mainLocation: {
    city: string;
    zipCode: string;
    shortAddress: string;
  };
}

export const useFormatCustomersData = ({
  handleOpenMap,
  customers,
  setRows,
  handleEditCustomer,
  setCustomer,
  inviteCustomer,
  loadingInvite,
  onDisplayMap,
  setSelectedCustomerId,
  onRefreshCreditScoreOk,
  setOpenCreateOrderDrawer,
}) => {
  const ability = useAbility();
  const theme = useSelector(selectTheme);
  const { t } = useTranslation();
  const activityCodes: any[] = useSelector(selectActivityCodes);
  const authUser = useSelector(selectAuthUser);
  const toast = useToaster();
  const [refreshCreditScore] = useRefreshCreditScoreMutation();
  const currentApplication = useApplication(authUser).id;

  const oneMonthAgo = dayjs().subtract(1, 'month');

  const handleRefreshCreditScore = async (customer: CustomersData) => {
    if (customer.legalId?.length < 9) {
      toast(3000, 'error', 'customer.creditScore.invalidLegalId');
    } else {
      const res = await refreshCreditScore(customer._id);

      // @ts-ignore
      if (res?.data) {
        onRefreshCreditScoreOk && onRefreshCreditScoreOk();
        toast(3000, 'success', 'customer.creditScore.ok');
      } else {
        toast(3000, 'error', 'customer.creditScore.error');
      }
    }
  };

  const handleDisplayMap = client => {
    onDisplayMap &&
      onDisplayMap(client?._id, client?.mainLocation?.geometry?.coordinates);
  };

  const handleCreateOrder = customer => {
    setCustomer(customer);
    setOpenCreateOrderDrawer(true);
  };

  const mapActivityCodes = useMemo(() => {
    const tmpMap = {};
    activityCodes.forEach((activityCode: any) => {
      tmpMap[activityCode.id] = activityCode.label;
    });

    return tmpMap;
  }, []);

  React.useEffect(() => {
    const rowsDatas: any = [];
    for (let i = 0; i < customers.length; i++) {
      let customerObj: CustomersData = customers[i];
      const formattedUpdatedAtDate = moment(customerObj.updatedAt)
        .subtract(1, 'hour')
        .format('YYYY/MM/DD HH:mm');
      const formattedCreatedAtDate = moment(customerObj.createdAt)
        .subtract(1, 'hour')
        .format('YYYY/MM/DD HH:mm');
      const actions = [
        {
          action: 'edit',
          element: (
            <>
              <PopoverEl redStatus={theme.redStatus}>
                <span
                  style={{ paddingLeft: themes?.default?.popoverLabelPadding }}
                >
                  {t(
                    !!ability.can('canedit', 'customers')
                      ? 'common.edit'
                      : 'view',
                  )}
                </span>
              </PopoverEl>
            </>
          ),
        },
        {
          action: 'portal',
          onClick: () => {
            window.open(
              `/portal/${customerObj.token}?password=${customerObj.tokenPassword}`,
              '_blank',
            );
          },
          element: (
            <PopoverEl
              redStatus={theme.redStatus}
              style={{ paddingLeft: themes?.default?.popoverLabelPadding }}
            >
              {t('nav.mainNav.ordoria.portal')}
            </PopoverEl>
          ),
        },
      ];

      const handleInpiLink = () => {
        window.open(
          `https://annuaire-entreprises.data.gouv.fr/etablissement/${customerObj.legalId}`,
        );
      };

      const InpiButton =
        customerObj.legalId?.length === 14 ? (
          <IconButton
            disableRipple
            sx={{ padding: '0 8px' }}
            onClick={() => {
              window.open(
                `https://annuaire-entreprises.data.gouv.fr/etablissement/${customerObj.legalId}`,
              );
            }}
          >
            <FileMagnifyingGlass />
          </IconButton>
        ) : !isEmpty(customerObj.legalId) ? (
          <IconButton
            disableRipple
            sx={{ padding: '0 8px' }}
            onClick={handleInpiLink}
          >
            <Warning />
          </IconButton>
        ) : null;

      if (checkUserAcl(authUser, ['manageCustomers'])) {
        actions.push({
          action: 'creditScore',
          onClick: () => {
            handleRefreshCreditScore(customerObj);
          },
          element: (
            <PopoverEl
              redStatus={theme.redStatus}
              style={{ paddingLeft: themes?.default?.popoverLabelPadding }}
            >
              {t('customer.creditScore.refresh')}
            </PopoverEl>
          ),
        });
      }

      if (currentApplication === 'ordoria') {
        actions.push({
          action: 'createOrder',
          onClick: () => handleCreateOrder(customerObj),
          element: (
            <PopoverEl
              redStatus={theme.redStatus}
              style={{ paddingLeft: themes?.default?.popoverLabelPadding }}
            >
              Create Order
            </PopoverEl>
          ),
        });
      }

      rowsDatas.push({
        data: createData(
          { value: customerObj._id },
          {
            value: <StatusCustomer status={customerObj.status} />,
          },
          {
            value: <EllipsisText width={200} text={customerObj.publicName} />,
            sort: customerObj.publicName,
          },
          {
            value: (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <If condition={!!customerObj.mainLocation}>
                  <AddressIcon
                    onClick={() => {
                      handleDisplayMap(customerObj);
                    }}
                  />
                  <EllipsisText
                    width={200}
                    text={
                      customerObj?.cache?.decks
                        ? customerObj?.cache?.decks[0]?.location?.name !==
                          undefined
                          ? customerObj?.cache?.decks[0]?.location?.name
                          : getAddressLabel(customerObj.mainLocation)
                        : getAddressLabel(customerObj.mainLocation)
                    }
                  />
                </If>
              </div>
            ),
          },
          {
            value: <span>{customerObj.phone}</span>,
            sort: customerObj.phone,
          },
          {
            value: <span>{customerObj.email}</span>,
            sort: customerObj.email,
          },
          {
            value: (
              <span>
                <FormatNumber number={customerObj.cache?.decks?.length || 0} />
              </span>
            ),
          },
          {
            value: (
              <span>
                <FormatNumber
                  number={parseFloat(
                    customerObj.cache?.stats?.amountPerWeight?.toFixed(2) ||
                      '0',
                  )}
                />
              </span>
            ),
          },
          {
            value: <span>{formattedCreatedAtDate} </span>,
            sort: customerObj.createdAt,
          },
          {
            value: <span>{customerObj.invoicePeriod}</span>,
            sort: customerObj.invoicePeriod,
          },
          {
            value: (
              <span>
                {customerObj.paymentPeriod}{' '}
                {customerObj.paymentPeriod > 1 ? t('days') : t('day')}
              </span>
            ),
            sort: customerObj.invoicePeriod,
          },
          {
            value: (
              <div style={{ minWidth: '165px' }}>
                {customerObj.legalStatus ? (
                  <Tooltip
                    title={
                      customerObj.legalStatus === 'C' &&
                      customerObj.legalStatusUpdatedAt
                        ? t(`customer.legalStatus.endedAt`, {
                            date: window
                              .dayjs(customerObj.legalStatusUpdatedAt)
                              .format('LL'),
                          })
                        : t(`customer.legalStatus.${customerObj.legalStatus}`)
                    }
                    arrow
                    placement="top"
                  >
                    <span>
                      {customerObj.legalId} ({customerObj.legalStatus}){' '}
                      {InpiButton}
                    </span>
                  </Tooltip>
                ) : (
                  <span>
                    {customerObj.legalId} {InpiButton}
                  </span>
                )}
              </div>
            ),
            sort: customerObj.legalId,
          },
          {
            value: (
              <Tooltip
                title={
                  mapActivityCodes[customerObj.activityCode] ||
                  customerObj.activityCode
                }
                arrow
                placement="top"
              >
                <span>{customerObj.activityCode}</span>
              </Tooltip>
            ),
            sort: customerObj.activityCode,
          },
          {
            value: <EllipsisText width={200} text={customerObj.legalName} />,
            sort: customerObj.legalName,
          },
          {
            value: <div>{customerObj.code}</div>,
            sort: customerObj.code,
          },
          {
            value: <span>{formattedUpdatedAtDate} </span>,
            sort: customerObj.updatedAt,
          },
          {
            value: <span>{customerObj.vatNumber} </span>,
            sort: customerObj.vatNumber,
          },
          {
            value: (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CreditScoreBadge
                  size="small"
                  score={customerObj.creditScore?.commonValue}
                />
              </div>
            ),
          },
          {
            value: (
              <div
                style={{
                  minWidth: '10rem',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {customerObj?.cache?.financials?.frequencyStatus && (
                  <GetCustomerFrequency
                    frequency={customerObj?.cache?.financials?.frequencyStatus}
                  />
                )}
              </div>
            ),
          },
          {
            value: (
              <SalesmanPopup
                _salesman={customerObj._salesman || ''}
                handleOpenMap={handleOpenMap}
              />
            ),
          },

          {
            value: (
              <EllipsisText
                width={150}
                text={customerObj._customerType?.label || ''}
              />
            ),
          },
          {
            value: (
              <ActionPopover
                id={customerObj.id}
                handleAction={(event, action) => {
                  if (action === 'edit') {
                    setCustomer(customerObj);
                    handleEditCustomer(event);
                  }
                }}
                actions={actions}
              />
            ),
          },
          {
            value: checkUserAcl(authUser, ['manageCustomers']) ? (
              <>
                {customerObj.status &&
                  !customerObj._lastLoggedUser &&
                  (!customerObj.invitedAt ||
                    (customerObj.invitedAt &&
                      dayjs(customerObj.invitedAt).isBefore(oneMonthAgo))) && (
                    <InvitationButton
                      variant="contained"
                      startIcon={
                        <PersonAddAlt1Outlined
                          sx={{ width: '12px', height: '12px' }}
                        />
                      }
                      onClick={() => {
                        inviteCustomer(customerObj);
                      }}
                    >
                      {t('customer.invite')}
                    </InvitationButton>
                  )}
                {customerObj.invitedAt &&
                  dayjs(customerObj.invitedAt).isAfter(oneMonthAgo) &&
                  !customerObj._lastLoggedUser && (
                    <InvitationSent
                      id={customerObj._id}
                      invitedAt={window
                        .dayjs(customerObj.invitedAt)
                        .format('LLL')}
                    />
                  )}

                {customerObj.invitedAt &&
                  dayjs(customerObj._lastLoggedUser).isBefore(
                    dayjs().subtract(6, 'month'),
                  ) && (
                    <CustomerInvitationWrapperInactive>
                      <CancelIcon sx={{ width: '12px', height: '12px' }} />
                      <div>{t('inactive')}</div>
                    </CustomerInvitationWrapperInactive>
                  )}
                {customerObj.invitedAt && customerObj._lastLoggedUser && (
                  <CustomerInvitationWrapperActive>
                    <CheckIcon sx={{ width: '12px', height: '12px' }} />
                    <div>{t('active')}</div>
                  </CustomerInvitationWrapperActive>
                )}
              </>
            ) : (
              <></>
            ),
          },
          {
            value: customerObj?.cache?.stats?.lastOrderDate ? (<StatusLastOrder date={customerObj?.cache?.stats?.lastOrderDate} displayTooltip />) : (<></>),
            sort: customerObj?.cache?.stats?.lastOrderDate?.toString() || '',
          }
        ),
      });
    }
    setRows(rowsDatas);
  }, [customers, loadingInvite]);
};

export const CustomerInvitationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  z-index: 100;
  font-size: 0.75rem;
`;

export const CustomerInvitationWrapperActive = styled(
  CustomerInvitationWrapper,
)`
  color: ${props => props.theme.fruitSaladGreen};
`;

export const CustomerInvitationWrapperInactive = styled(
  CustomerInvitationWrapper,
)`
  color: ${props => props.theme.Cinnabar};
`;

export const InvitationButton = styled(Button)`
  padding: 5px 12px 4px 12px;
  border-radius: 4px;
  background: ${props => props.theme.blueGradiant};
  font-size: 0.75rem;
  text-transform: none !important;
  width: 70px;
  height: 20px;
`;
