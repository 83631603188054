import { Grid, Grow, Modal } from '@mui/material';
import { useEffect, useLayoutEffect, useState } from 'react';
import { NotificationToolbar } from './components/NotificationToolbar';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { useTranslation } from 'react-i18next';
import {
  useActivitiesReadMutation,
  useGetActivitiesQuery,
} from 'common/services/activityApi';
import { ActionCard } from './components/ActionCard';
import { UnderToolbarNotification } from './components/UnderToolbarNotification';
import If from '../If';
import { isOpen } from '@blueprintjs/core/lib/esnext/components/context-menu/contextMenu';

export function Notifications({
  open,
  onClose,
  totalImportant, totalOther,
}) {
  const { t } = useTranslation();
  const [limit, setLimit] = useState(30);
  const [viewMode, setViewMode] = useState<'modal' | 'sidebar'>('sidebar');
  const [selectedUnreadIds, setSelectedUnreadIds] = useState<string[]>([]);
  const [tabIndex, setTabIndex] = useState<number>(0);

  console.log(`limit=${limit}&important=${tabIndex === 0}`)
  const {
    data: activities,
    isLoading,
    isFetching,
    refetch,
  } = useGetActivitiesQuery(`limit=${limit}&important=${tabIndex === 0}`);

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 2
      ) {
        setLimit(prevLimit => prevLimit + 5);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  /* --------- API CALLS ---------- */

  const [readActivities] = useActivitiesReadMutation();

  useEffect(() => {
    if (open && !isFetching) {
      refetch();
    }

  }, [open, tabIndex]);


  /*
  useEffect(() => {
    if (open) {
      refetch();
    } else {
      // setLimit(30);
    }
  }, [open, tabIndex]);
  */

  useEffect(() => {
    if (activities?.docs && open) {
      const unreaded = activities?.docs?.filter(
        activity =>
          activity?.read === false &&
          !selectedUnreadIds.includes(activity?._id),
      );

      setSelectedUnreadIds(prevIds => [
        ...prevIds,
        ...unreaded.map(activity => activity?._id),
      ]);
    }
  }, [activities, open]);

  function handleFetchCardsOnScroll(e) {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 1;
    if (bottom) {
      setLimit(limit + 5);
    }
  }

  function readNotifications() {
    if (selectedUnreadIds.length > 0) {
      readActivities({ activityIds: selectedUnreadIds });
      setSelectedUnreadIds([]);
    }
  }

  function handleReadNotification(id: string, isRead) {
    if (!isRead) {
      readActivities({ activityIds: [id] });
      setSelectedUnreadIds([]);
    }
  }

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Grow in={open} timeout={{ enter: 500, exit: 200 }}>
          <Wrapper isOnSide={viewMode === 'sidebar'}>
            <ChatSectionWrapper isOnSide={viewMode === 'sidebar'}>
              <NotificationToolbar
                viewMode={viewMode}
                setViewMode={setViewMode}
                handleCloseChat={onClose}
              />
              <UnderToolbarNotification
                readNotifications={readNotifications}
                totalImportant={totalImportant}
                totalOther={totalOther}
                onTabChange={setTabIndex}
                tabIndex={tabIndex}
              />
              <ChatSection>
                <If condition={!isLoading}>
                  <Grid
                    container
                    onScroll={e => handleFetchCardsOnScroll(e)}
                    sx={{
                      overflow: 'auto',
                      height: 'calc(100% - 20px)',
                      padding: '20px',
                    }}
                  >
                    <Grid container flexDirection="column" gap={1} sx={{ opacity: isFetching ? 0.5 : 1 }}>
                      { activities?.docs?.map(activity => (
                        <ActionCard
                          key={activity?._id}
                          isReaden={activity?.read}
                          activity={activity}
                          onCardClick={handleReadNotification}
                        />
                      ))}
                    </Grid>
                  </Grid>
                </If>
              </ChatSection>
            </ChatSectionWrapper>
          </Wrapper>
        </Grow>
      </Modal>
    </>
  );
}

export const Wrapper = styled.div<{ isOnSide?: boolean }>`
  display: flex;
  height: ${props =>
    props.isOnSide ? '100vh' : 'calc(100vh - 39px - 39px - 40px)'};
  max-width: 1040px;
  width: 100%;
  gap: 12px;
  position: ${props => (props.isOnSide ? 'absolute' : 'relative')};
  margin: ${props => (props.isOnSide ? '0' : '39px auto')};
  right: ${props => (props.isOnSide ? '0' : 'none')};
`;

export const ChatSectionWrapper = styled.div<{ isOnSide?: boolean }>`
  flex: 1;
  background: linear-gradient(
    55deg,
    rgba(255, 0, 255, 0.3) -14.9%,
    rgba(176, 30, 255, 0.3) 10.07%,
    rgba(17, 89, 255, 0.3) 93.5%
  );
  padding: 2px;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: -24px 24px 48px -12px #674daf57;
  border-radius: ${props => (props.isOnSide ? '20px 0 0 0' : '20px')};
`;

const ChatSection = styled.div<{ isOnSide?: boolean }>`
  flex: 1;
  background: ${themes?.default?.accordionWhiteBg};
  border-radius: 0 0 18px 18px;
  border-radius: ${props => (props.isOnSide ? '0' : '0 0 18px 18px')};
  display: flex;
  height: calc(100% - 2px - 32px - 1px - 2px);
`;
