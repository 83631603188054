import {
  Box,
  Button,
  Chip,
  Collapse,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { SetStateAction, useState } from 'react';
import WarningIcon from '@mui/icons-material/WarningAmber';
import { useTranslation } from 'react-i18next';
import { FileMagnifyingGlass } from '@phosphor-icons/react';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
interface TablePaymentsProps {
  payments: any[];
  onSwitchStatus: Function;
}

export function TablePayments({
                           payments,
                           onSwitchStatus,
                         }: TablePaymentsProps) {
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);

  return (
    <>
      <Grid
        sx={{
          m: '10px 20px',
        }}
      >
        <TableContainer>
          <Table
            size="small"
            sx={{
              backgroundColor: 'white',
            }}
          >
            <TableHead
              sx={{
                backgroundColor: '#F4F4F4',
                height: '10px',
              }}
            >
              <TableRow>
                <TableCell align="left">
                  {t('common.date').toUpperCase()}
                </TableCell>
                <TableCell align="left">
                  {t('common.customer').toUpperCase()}
                </TableCell>
                <TableCell>{t('payments.amount').toUpperCase()}</TableCell>
                <TableCell>{t('payments.paymentMode.label').toUpperCase()}</TableCell>
                <TableCell>{t('payments.invoice').toUpperCase()}</TableCell>
                <TableCell>{t('payments.orderCode').toUpperCase()}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {payments?.map((doc, index) => {
                const invoice = doc?._files?.[0] || null;

                console.log(doc)
                return (
                  <>
                    <TableRow
                      key={index}
                      sx={{
                        ':hover': {
                          backgroundColor: '#F8F8F8',
                          cursor: 'pointer',
                        },
                      }}
                    >
                      <TableCell>
                        <Typography
                        >
                          {window.dayjs(doc?.statusUpdatedAt).format('LLL')}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            display: 'inline',
                            ':hover': {
                              textDecoration: 'underline',
                              color: '#2A59FF',
                            },
                          }}
                        >
                          {doc?._customer.code} - {doc?._customer.legalName}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                        >
                          {doc?.amount}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {t(`payments.paymentMode.${doc.type}`)}
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            display: 'inline',
                            ':hover': {
                              textDecoration: 'underline',
                              color: '#2A59FF',
                            },
                          }}
                          onClick={() => {
                            window.open(
                              invoice.url,
                            );
                          }}
                        >{invoice?.metadata?.invoiceCode}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{invoice?.metadata?.orderCode}</Typography>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          color={doc.accountingStatus === 'unprocessed' ? 'primary' : 'error'}
                          sx={{ marginRight: 5 }}
                          onClick={() => onSwitchStatus(doc)}
                        >
                          {doc.accountingStatus === 'unprocessed' ? t('payments.processAccounting') : t('common.cancel')}
                        </Button>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
}
